const ColorsBg = [
  {
    bg: "white",
  },
  {
    bg: "gray-700",
  },
  {
    bg: "primary",
  },
  {
    bg: "success",
  },
  {
    bg: "danger",
  },
  {
    bg: "info",
  },
];
const SidebarColorsBg = [
  {
    bg: "white",
  },

  {
    bg: "gray-700",
  },
  {
    bg: "danger",
  },
  {
    bg: "info",
  },
  {
    bg: "success",
  },
  {
    bg: "primary",
  },
];

export { ColorsBg, SidebarColorsBg };
