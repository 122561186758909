import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import request from '../../RequestHandler';
import Loader from '../../loader/Loader';
import 'react-toastify/dist/ReactToastify.css';

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryToUpdate, setCategoryToUpdate] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        subheading: '',
        description: '',
        featured_image: null,
        gallery: [],
    });
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchCategories = async () => {
            setLoading(true);
            try {
                const response = await request('get', 'categories', {}, token);
                setCategories(response.categories);
                toast.success(response.message);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Error fetching categories');
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, [token]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        const newValue = type === 'file' ? files[0] : value;

        setFormData((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key] !== null && formData[key] !== undefined) {
                if (key === 'gallery') {
                    for (let i = 0; i < formData.gallery.length; i++) {
                        formDataToSend.append('gallery[]', formData.gallery[i]);
                    }
                } else {
                    formDataToSend.append(key, formData[key]);
                }
            }
        });

        try {
            let response;
            if (categoryToUpdate) {
                response = await request('put', `categories/${categoryToUpdate.id}`, formDataToSend, token);
                setCategories(prevCategories => prevCategories.map(category => category.id === response.category.id ? response.category : category));
                toast.success('Category updated successfully');
            } else {
                response = await request('post', 'categories', formDataToSend, token);
                setCategories(prevCategories => [...prevCategories, response.category]);
                toast.success('Category added successfully');
            }
            setCategoryToUpdate(null);
            setFormData({
                name: '',
                subheading: '',
                description: '',
                featured_image: null,
                gallery: [],
            });
        } catch (error) {
            console.error('Error adding/updating category:', error);
            toast.error('Error adding/updating category');
        }
    };


    const handleUpdate = (category) => {
        setCategoryToUpdate(category);
        setFormData({
            name: category.name,
            subheading: category.subheading,
            description: category.description,
            featured_image: null,
            gallery: [],
        });
    };

    const handleDelete = async (id) => {
        try {
            await request('delete', `categories/${id}`, {}, token);
            setCategories(categories.filter(category => category.id !== id));
            toast.success('Category deleted successfully');
        } catch (error) {
            console.error('Error deleting category:', error);
            toast.error('Error deleting category');
        }
    };

    const openImage = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    return (
        <div className="container">
            <h2>Manage Categories</h2>
            {loading && <Loader />}
            <form onSubmit={handleSubmit} className="my-4">
                <h3>{categoryToUpdate ? 'Update Category' : 'Add Category'}</h3>
                <div className="form-group">
                    <label htmlFor="name">Name:
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="subheading">Sub Heading:
                        <input
                            type="text"
                            className="form-control"
                            id="subheading"
                            name="subheading"
                            value={formData.subheading}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description:
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        ></textarea>
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="featured_image">Featured Image:
                        <input
                            type="file"
                            className="form-control-file"
                            id="featured_image"
                            name="featured_image"
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="gallery">Gallery Images:
                        <input
                            type="file"
                            className="form-control-file"
                            id="gallery"
                            name="gallery"
                            multiple
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    {categoryToUpdate ? 'Update Category' : 'Add Category'}
                </button>
            </form>
            <div className="table-responsive">
                <table className="table table-striped custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th>Name</th>
                            <th>Sub Heading</th>
                            <th>Description</th>
                            <th>Featured Image</th>
                            <th>Gallery</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {categories.map((category) => (
                            <tr key={category.id}>
                                <td>{category.name}</td>
                                <td>{category.subheading || '-'}</td>
                                <td>{category.description || '-'}</td>
                                <td>
                                    {category.featured_image && (
                                        <div className="image-container" onClick={() => openImage(category.featured_image)}>
                                            <img className="image-thumbnail" src={category.featured_image} alt="Featured" />
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {category.gallery.length > 0 ? (
                                        <ul>
                                            {category.gallery.map((image, index) => (
                                                <li key={category.id}>
                                                    <div className="image-container" onClick={() => openImage(image)}>
                                                        <img className="image-thumbnail" src={image} alt={`Gallery ${index + 1}`} />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>
                                    <>
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => handleUpdate(category)}>
                                            Update
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => handleDelete(category.id)}>
                                            Delete
                                        </button>
                                    </>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Categories;
