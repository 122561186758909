import React from 'react';
import { useSelector } from 'react-redux';

import LogoDarkText from '../../assets/images/logos/logo.png';


const AuthLogo = () => {
  const isDarkMode = useSelector((state) => state.customizer.isDark);

  return (
    <div className="p-4 d-flex justify-content-center gap-2">
      {isDarkMode !== false ? (
        <>

          <img src={LogoDarkText} className="d-none d-lg-block" alt='logo' />
        </>
      ) : (
        <>

          <img src={LogoDarkText} className="d-none d-lg-block" alt='logo' />
        </>
      )}
    </div>
  );
};

export default AuthLogo;
