import React, { useState } from 'react';
import PropTypes from 'prop-types';

const DestinationForm = ({ onAdd }) => {
    const [formData, setFormData] = useState({
        name: '',
        sub_heading: '',
        description: '',
        state_name: '',
        is_top: 0, // Initially set to 0 for 'No'
        featured_image: null,
        gallery: [],
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        setFormData((prev) => ({
            ...prev,
            [name]: newValue,
        }));
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (name === 'gallery') {
            setFormData((prev) => ({
                ...prev,
                [name]: Array.from(files), // Convert FileList to Array
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: files[0], // Single file input
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();

        // Append each field to the formData object
        formDataToSend.append('name', formData.name);
        formDataToSend.append('sub_heading', formData.sub_heading);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('state_name', formData.state_name);
        formDataToSend.append('is_top', formData.is_top); // Convert boolean to 0 or 1

        if (formData.featured_image) {
            formDataToSend.append('featured_image', formData.featured_image);
        }

        if (formData.gallery.length > 0) {
            formData.gallery.forEach((file) => {
                formDataToSend.append('gallery[]', file);
            });
        }

        onAdd(formDataToSend);
    };

    return (
        <div className="my-4">
            <h3>Add Destination</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name:
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="sub_heading">Sub Heading:
                        <input
                            type="text"
                            className="form-control"
                            id="sub_heading"
                            name="sub_heading"
                            value={formData.sub_heading}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        ></textarea>
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="state_name">State Name:
                        <input
                            type="text"
                            className="form-control"
                            id="state_name"
                            name="state_name"
                            value={formData.state_name}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="is_top">Is Top:
                        <select
                            className="form-control"
                            id="is_top"
                            name="is_top"
                            value={formData.is_top} // No need to convert to string
                            onChange={handleChange}
                        >
                            <option value={0}>No</option>
                            <option value={1}>Yes</option>
                        </select>
                    </label>
                </div>

                <div className="form-group">
                    <label htmlFor="featured_image">Featured Image:
                        <input
                            type="file"
                            className="form-control-file"
                            id="featured_image"
                            name="featured_image"
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="gallery">Gallery Images:
                        <input
                            type="file"
                            className="form-control-file"
                            id="gallery"
                            name="gallery"
                            multiple
                            onChange={handleFileChange}
                        />
                    </label>
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    Add Destination
                </button>
            </form>
        </div>
    );
};

DestinationForm.propTypes = {
    onAdd: PropTypes.func.isRequired,
};

export default DestinationForm;
