/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import { store } from './store/Store';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';
import './data';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <App />
      <ToastContainer />
    </BrowserRouter>
  </Provider>
);

// Optionally log results to an analytics endpoint.
reportWebVitals(console.log);
