import React from "react";
import "./loader.scss";

const Loader = () => (
  <div className="fallback-spinner">
    <div className="loading">
      <img src="/logo.png" alt="Loading..." className="loading-logo" />
    </div>
  </div>
);

export default Loader;
