import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import request from '../../RequestHandler';
import Loader from '../../loader/Loader';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

const AllDestinations = () => {
    const [destinations, setDestinations] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchDestinations = async () => {
            setLoading(true);
            try {
                const response = await request('get', 'destinations-all', {}, token);
                setDestinations(response.destinations);
                toast.success(response.message);
            } catch (error) {
                console.error('Error fetching destinations:', error);
                toast.error('Error fetching destinations');
            } finally {
                setLoading(false);
            }
        };

        fetchDestinations();
    }, [token]);

    const handleUpdate = (destinationId) => {
        console.log('Update destination with ID:', destinationId);
    };

    const handleDelete = async (destinationId) => {
        try {
            await request('delete', `destinations/${destinationId}`, {}, token);
            setDestinations(destinations.filter(dest => dest.id !== destinationId));
            toast.success('Destination deleted successfully');
        } catch (error) {
            console.error('Error deleting destination:', error);
            toast.error('Error deleting destination');
        }
    };

    const openImage = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    return (
        <div className="container">
            <h2>All Destinations</h2>
            {loading && <Loader />}
            <div className="table-responsive">
                <table className="table table-striped custom-table">
                    <thead className="thead-light">
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>State</th>
                            <th>Is Top</th>
                            <th>Featured Image</th>
                            <th>Gallery</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {destinations.map((destination) => (
                            <tr key={destination.id}>
                                <td>{destination.name}</td>
                                <td>{destination.description || '-'}</td>
                                <td>{destination.state_name || '-'}</td>
                                <td>{destination.is_top ? 'Yes' : 'No'}</td>
                                <td>
                                    {destination.featured_image && (
                                        <div className="image-container" onClick={() => openImage(destination.featured_image)}>
                                            <img className="image-thumbnail" src={destination.featured_image} alt="Featured" />
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {destination.gallery.length > 0 ? (
                                        <ul>
                                            {destination.gallery.map((image, index) => (
                                                <li key={destination.id}>
                                                    <div className="image-container" onClick={() => openImage(image)}>
                                                        <img className="image-thumbnail" src={image} alt={`Gallery ${index + 1}`} />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>
                                    <>
                                        <button type="button" className="btn btn-primary mr-2" onClick={() => handleUpdate(destination.id)}>
                                            Update
                                        </button>
                                        <button type="button" className="btn btn-danger" onClick={() => handleDelete(destination.id)}>
                                            Delete
                                        </button>
                                    </>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AllDestinations;
