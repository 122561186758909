// src/routes/ThemeRoutes.js

// import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// import Loadable from '../layouts/loader/Loadable';
import PrivateRoute from '../PrivateRoute';

// Import the necessary layouts and pages
import FullLayout from '../layouts/FullLayout';
import BlankLayout from '../layouts/BlankLayout';
import Minimal from '../views/dashboards/Minimal';
import TopDestinations from '../views/TopDestinationsList';
import AllDestinations from '../views/AllDestinations';
import Categories from '../views/Categories';
import Analytical from '../views/dashboards/Analytical';
import Demographical from '../views/dashboards/Demographical';
import Modern from '../views/dashboards/Modern';
import Notes from '../views/apps/notes/Notes';
import Chat from '../views/apps/chat/Chat';
import Contacts from '../views/apps/contacts/Contacts';
import Calendar from '../views/apps/calendar/CalendarApp';
import Email from '../views/apps/email/Email';
import Shop from '../views/apps/ecommerce/Shop';
import ShopDetail from '../views/apps/ecommerce/ShopDetail';
import Treeview from '../views/apps/treeview/TreeView';
import TicketList from '../views/apps/ticket/TicketList';
import TicketDetail from '../views/apps/ticket/TicketDetail';
import Alerts from '../views/ui/Alerts';
import Badges from '../views/ui/Badges';
import Buttons from '../views/ui/Buttons';
import Cards from '../views/ui/Cards';
import Grid from '../views/ui/Grid';
import Tables from '../views/ui/Tables';
import Forms from '../views/ui/Forms';
import Breadcrumbs from '../views/ui/Breadcrumbs';
import Dropdowns from '../views/ui/DropDown';
import BtnGroup from '../views/ui/BtnGroup';
import Collapse from '../views/ui/Collapse';
import ListGroup from '../views/ui/ListGroup';
import Modal from '../views/ui/Modal';
import Navbar from '../views/ui/Navbar';
import Nav from '../views/ui/Nav';
import Pagination from '../views/ui/Pagination';
import Popover from '../views/ui/Popover';
import Progress from '../views/ui/Progress';
import Spinner from '../views/ui/Spinner';
import Tabs from '../views/ui/Tabs';
import Toasts from '../views/ui/Toasts';
import Tooltip from '../views/ui/Tooltip';
import FormBasic from '../views/form-layouts/FormBasic';
import FormGrid from '../views/form-layouts/FormGrid';
import FormGroup from '../views/form-layouts/FormGroup';
import FormInput from '../views/form-layouts/FormInput';
import Datepicker from '../views/form-pickers/DateTimePicker';
import TagSelect from '../views/form-pickers/TagSelect';
import FormValidate from '../views/form-validation/FormValidation';
import FormSteps from '../views/form-steps/Steps';
import FormEditor from '../views/form-editor/FormEditor';
import Basictable from '../views/tables/TableBasic';
import CustomReactTable from '../views/tables/CustomReactTable';
import ReactBootstrapTable from '../views/tables/ReactBootstrapTable';
import ApexCharts from '../views/charts/ApexCharts';
import ChartJs from '../views/charts/ChartJs';
import StarterKit from '../views/sample-pages/StarterKit';
import Profile from '../views/sample-pages/Profile';
import Gallery from '../views/sample-pages/Gallery';
import SearchResult from '../views/sample-pages/SearchResult';
import HelperClass from '../views/sample-pages/HelperClass';
import Bootstrap from '../views/icons/Bootstrap';
import Feather from '../views/icons/Feather';
import CustomVectorMap from '../views/maps/CustomVectorMap';
import Widget from '../views/widget/Widget';
import CASL from '../views/apps/accessControlCASL/AccessControl';
import Error from '../views/auth/Error';
import RegisterFormik from '../views/auth/RegisterFormik';
import LoginFormik from '../views/auth/LoginFormik';
import Maintanance from '../views/auth/Maintanance';
import LockScreen from '../views/auth/LockScreen';
import RecoverPassword from '../views/auth/RecoverPassword';

const ThemeRoutes = [
  {
    path: '/',
    element: <PrivateRoute><FullLayout /></PrivateRoute>,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboards" /> },
      { path: '/dashboards', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/destinations/top', name: 'TopDestinations', exact: true, element: <TopDestinations /> },
      { path: '/destinations/all', name: 'AllDestinations', exact: true, element: <AllDestinations /> },
      { path: '/categories', name: 'Categories', exact: true, element: <Categories /> },
      { path: '/dashboards/analytical', name: 'Analytical', exact: true, element: <Analytical /> },
      { path: '/dashboards/demographical', name: 'Demographical', exact: true, element: <Demographical /> },
      { path: '/dashboards/modern', name: 'Modern', exact: true, element: <Modern /> },
      { path: '/apps/notes', name: 'notes', exact: true, element: <Notes /> },
      { path: '/apps/chat', name: 'chat', exact: true, element: <Chat /> },
      { path: '/apps/contacts', name: 'contacts', exact: true, element: <Contacts /> },
      { path: '/apps/calendar', name: 'calendar', exact: true, element: <Calendar /> },
      { path: '/apps/email', name: 'email', exact: true, element: <Email /> },
      { path: '/ecom/shop', name: 'shop', exact: true, element: <Shop /> },
      { path: '/ecom/shopdetail', name: 'shopdetail', exact: true, element: <ShopDetail /> },
      { path: '/tickt/ticket-list', name: 'ticket list', exact: true, element: <TicketList /> },
      { path: '/tickt/ticket-detail', name: 'ticket detail', exact: true, element: <TicketDetail /> },
      { path: '/apps/treeview', name: 'treeview', exact: true, element: <Treeview /> },
      { path: '/ui/alerts', name: 'alerts', exact: true, element: <Alerts /> },
      { path: '/ui/badges', name: 'badges', exact: true, element: <Badges /> },
      { path: '/ui/buttons', name: 'buttons', exact: true, element: <Buttons /> },
      { path: '/ui/cards', name: 'cards', exact: true, element: <Cards /> },
      { path: '/ui/grid', name: 'grid', exact: true, element: <Grid /> },
      { path: '/ui/table', name: 'table', exact: true, element: <Tables /> },
      { path: '/ui/forms', name: 'forms', exact: true, element: <Forms /> },
      { path: '/ui/breadcrumbs', name: 'breadcrumbs', exact: true, element: <Breadcrumbs /> },
      { path: '/ui/dropdown', name: 'dropdown', exact: true, element: <Dropdowns /> },
      { path: '/ui/button-group', name: 'button group', exact: true, element: <BtnGroup /> },
      { path: '/ui/collapse', name: 'collapse', exact: true, element: <Collapse /> },
      { path: '/ui/list-group', name: 'list-group', exact: true, element: <ListGroup /> },
      { path: '/ui/modal', name: 'modal', exact: true, element: <Modal /> },
      { path: '/ui/navbar', name: 'navbar', exact: true, element: <Navbar /> },
      { path: '/ui/nav', name: 'nav', exact: true, element: <Nav /> },
      { path: '/ui/pagination', name: 'pagination', exact: true, element: <Pagination /> },
      { path: '/ui/popover', name: 'popover', exact: true, element: <Popover /> },
      { path: '/ui/progress', name: 'progress', exact: true, element: <Progress /> },
      { path: '/ui/spinner', name: 'spinner', exact: true, element: <Spinner /> },
      { path: '/ui/tabs', name: 'tabs', exact: true, element: <Tabs /> },
      { path: '/ui/toasts', name: 'toasts', exact: true, element: <Toasts /> },
      { path: '/ui/tooltip', name: 'tooltip', exact: true, element: <Tooltip /> },
      { path: '/form-layout/form-basic', name: 'form-basic', exact: true, element: <FormBasic /> },
      { path: '/form-layout/form-grid', name: 'form-grid', exact: true, element: <FormGrid /> },
      { path: '/form-layout/form-group', name: 'form-group', exact: true, element: <FormGroup /> },
      { path: '/form-layout/form-input', name: 'form-input', exact: true, element: <FormInput /> },
      { path: '/form-pickers/datepicker', name: 'datepicker', exact: true, element: <Datepicker /> },
      { path: '/form-pickers/tag-select', name: 'tag-select', exact: true, element: <TagSelect /> },
      { path: '/form-validation', name: 'form-validation', exact: true, element: <FormValidate /> },
      { path: '/form-steps', name: 'form-steps', exact: true, element: <FormSteps /> },
      { path: '/form-editor', name: 'form-editor', exact: true, element: <FormEditor /> },
      { path: '/tables/basic-table', name: 'basic-table', exact: true, element: <Basictable /> },
      { path: '/tables/react-table', name: 'react-table', exact: true, element: <CustomReactTable /> },
      { path: '/tables/data-table', name: 'data-table', exact: true, element: <ReactBootstrapTable /> },
      { path: '/charts/apex', name: 'apex', exact: true, element: <ApexCharts /> },
      { path: '/charts/chartjs', name: 'chartjs', exact: true, element: <ChartJs /> },
      { path: '/profile', name: 'profile', exact: true, element: <Profile /> },
      { path: '/sample-pages/helper-class', name: 'helper-class', exact: true, element: <HelperClass /> },
      { path: '/sample-pages/starterkit', name: 'starterkit', exact: true, element: <StarterKit /> },
      { path: '/sample-pages/gallery', name: 'gallery', exact: true, element: <Gallery /> },
      { path: '/sample-pages/search-result', name: 'search-result', exact: true, element: <SearchResult /> },
      { path: '/icons/bootstrap', name: 'bootstrap', exact: true, element: <Bootstrap /> },
      { path: '/icons/feather', name: 'feather', exact: true, element: <Feather /> },
      { path: '/map/vector', name: 'vector', exact: true, element: <CustomVectorMap /> },
      { path: '/widget', name: 'widget', exact: true, element: <Widget /> },
      { path: '/casl', name: 'casl', exact: true, element: <CASL /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'register', element: <RegisterFormik /> },
      { path: 'login', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export default ThemeRoutes;
