import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import request from '../../RequestHandler';
import DestinationForm from './DestinationForm';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import Loader from '../../loader/Loader';

const TopDestinations = () => {
    const [destinations, setDestinations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [adding, setAdding] = useState(false);

    const token = localStorage.getItem('token');

    // Fetch data from API on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await request('get', 'destinations/top', {}, token);
                setDestinations(data.destinations);
                setLoading(false);
                toast.success(data.message);
                console.log(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data');
                setLoading(false);
            }
        };

        fetchData();
    }, [token]);

    const handleUpdate = (updatedDestination) => {
        setDestinations((prev) =>
            prev.map((dest) =>
                dest.id === updatedDestination.id ? updatedDestination : dest
            )
        );
        toast.success('Update successful');
    };

    const handleDelete = async (deletedDestinationId) => {
        try {
            await request('delete', `destinations/${deletedDestinationId}`, {}, token); // Assuming endpoint for deleting a destination
            setDestinations((prev) => prev.filter((dest) => dest.id !== deletedDestinationId));
            toast.success('Delete successful');
        } catch (error) {
            console.error('Error deleting destination:', error);
            toast.error('Error deleting destination');
        }
    };

    const handleAdd = async (newDestination) => {
        try {
            setAdding(true);
            const response = await request('post', 'destinations', newDestination, token, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.message, "dfdfd");
            if (response.data && response.data.destination) {
                setDestinations((prev) => [...prev, response.data.destination]);
                toast.success(response.message);

            }
            setAdding(false);
            toast.success(response.message);

        } catch (error) {
            console.error('Error adding destination:', error);
            toast.error(`Error adding destination: ${error.message}`);
            setAdding(false);
        }
    };

    const openImage = (imageUrl) => {
        const fullImageUrl = `${imageUrl}`;
        window.open(fullImageUrl, '_blank');
    };

    return (
        <div className="container">
            <h2 className="my-4">Top Destinations</h2>
            {loading ? (
                <Loader /> // Show loader when loading data
            ) : destinations.length === 0 ? (
                <p>No top destinations found.</p>
            ) : (
                <div className="table-container">
                    <table className="table table-striped">
                        <thead className="thead-light">
                            <tr>
                                <th>Name</th>
                                <th>Sub Heading</th>
                                <th>Description</th>
                                <th>State</th>
                                <th>Is Top</th>
                                <th>Featured Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {destinations.map((destination) => (
                                <tr key={destination.id}>
                                    <td>{destination.name}</td>
                                    <td>{destination.sub_heading}</td>
                                    <td>{destination.description}</td>
                                    <td>{destination.state_name}</td>
                                    <td>{destination.is_top ? 'Yes' : 'No'}</td>

                                    <td>
                                        {destination.featured_image && (
                                            <div
                                                className="image-container"
                                                onClick={() => openImage(destination.featured_image)}
                                            >
                                                <img
                                                    className="image-feature"
                                                    src={`${destination.featured_image}`}
                                                    alt="Featured"
                                                />
                                            </div>
                                        )}
                                    </td>

                                    <td>
                                        <button
                                            type="button"
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleUpdate(destination)}
                                        >
                                            Update
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => handleDelete(destination.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {adding ? (
                <Loader /> // Show loader when adding a new destination
            ) : (
                <DestinationForm onAdd={handleAdd} />
            )}
        </div>
    );
};

export default TopDestinations;
